export const VALUE_WITH_LOYALTY = 1;

export const VALUE_WITHOUT_LOYALTY = 0;

export const FIXED_INTERNET = "INTERNET_FIXA";
export const MOBILE_INTERNET = "MOVEL";
export const FIXED_PHONE = "TELEFONE_FIXO";
export const ADDITIONAL_SERVICES = "SVA";
export const TV = "TV";

export const BRADESCO_BANK = 237;
export const BANCO_DO_BRASIL_BANK = 1;

export const ENDPOINT_API_V2 = process.env.VUE_APP_URL_API_V2;

export const LOYALTY = { 1: "Fidelizado", 0: "Não fidelizado" };

export const LENGTH_POSTCODE = 8;

export const SEARCH_CEP =
  "https://buscacepinter.correios.com.br/app/endereco/index.php";

export const MASK_BANKS = {
  237: "#######-X",
  341: "#####-#",
  260: "######-#",
  33: "########-#",
  1: "########-X",
  104: "###########-#",
  77: "#######-#",
};

export const ICONS_FEEDBACK = {
  warn: "exclamation-circle",
  error: "exclamation-circle",
  success: "check-circle",
  info: "info-circle",
};

export const PAYMENT_AVAILABLE = {
  DIGITAL: "E-mail ( fatura digital )",
  CORREIOS: "Correios ( fatura impressa )",
};

export const PAYMENT_AVAILABLE_TERRA_FIXED_INTERNET = {
  DIGITAL: "E-mail ( fatura digital )",
};

export const PAYMENT_AVAILABLE_VIVO_RESIDENCIAL = {
  DIGITAL: "E-mail ( fatura digital )",
};

export const SERVICES = {
  MIGRACAO: "Migração",
  PORTABILIDADE: "Portabilidade",
  ATIVACAO: "Ativação",
};

export const PAYMENT_TYPE = {
  DEBITO_AUTOMATICO: "Débito automático",
  BOLETO: "Boleto bancário",
  CARTAO_CREDITO: "Cartão de crédito",
};

export const MODALITIES = {
  CONTROLE_BOLETO: "Controle boleto",
  POS_PAGO: "Pós pago",
  CONTROLE_FACIL: "Controle fácil",
  PRE_PAGO: "Pré pago",
  CONTROLE_FACIL_BOLETO: "Controle fácil boleto",
  CONTROLE: "Controle",
  EXPRESS: "Express",
  TVPRE: "TV Pré",
  RESIDENCIAL: "Residencial",
};

export const STATUS_FEEDBACK = {
  CANCELADO: "error",
  EM_PROCESSAMENTO: "info",
  PENDENTE: "warn",
  CONCRETIZADO: "success",
  EM_ANALISE: "success",
  EM_CONTINGENCIA: "warn",
  NAO_CONVERTIDO: "error",
  CONVERTIDO: "success",
  RCV: "success",
  INDICACAO_RECEBIDA: "success",
};

export const TITLE_FEEDBACK = {
  EM_ANALISE: "Contratação Realizada",
  EM_CONTINGENCIA: "Aguardando Verificação",
  NAO_CONVERTIDO: "Falha na contratação",
  CONVERTIDO: "Contratação Realizada",
  RCV: "Contratação Realizada",
  INDICACAO_RECEBIDA: "Pedido Recebido",
  CONCRETIZADO: "Pedido ativado",
  EM_PROCESSAMENTO: "Pedido cadastrado",
  CANCELADO: "Pedido cancelado",
  PENDENTE: "Pedido pendente",
};

export const FEEDBACK_VOICE_VERIFY = {
  PENDENTE: {
    status: "warn",
    title: "Pedido Pendente de Aceite de Voz",
    description:
      "O pedido já foi enviado, estamos consultado no sistema junto a Claro para identificar a realização do Aceite de Voz realizado pelo cliente.",
  },
  SAIDA_ACEITE_VOZ: {
    status: "warn",
    title: "Pedido Pendente de Aceite de Voz",
    description:
      "O pedido ainda não foi enviado, devido a ausência do aceite de voz, necessário para o procecimento de contratação do plano junto a Claro. Realize o aceite de voz para o pedido ser concluído.",
  },
  NAO_ACEITO: {
    status: "error",
    title: "Pedido Cancelado",
    description:
      "Esse pedido foi cancelado e não pode ser registrado ou comissionado. Inicie uma nova venda clicando abaixo.",
  },
  ACEITO: {
    status: "success",
    title: "Pedido Concretizado",
    description:
      "Pedido Enviamos uma mensagem com todos os detalhes do pedido para o e-mail do cliente cadastrado nas etapas iniciais do pedido.",
  },
};

export const DEFAULT_ENDPOINT = "vemqda";

export const ICONS_PNG_FREE_APPS_COMPARATIVE = [
  "claromusica",
  "clarovideo",
  "claronoticias",
  "clarobanca",
  "now",
  "skeelo",
  "netflix",
  "youtube",
  "plutotv",
  "globoplay",
  "twitch",
];

export const ITEMS_PER_PAGE = 20;

export const STATUS_SALE = {
  EM_PROCESSAMENTO: "Processando",
  PENDENTE: "Pendente",
  CONCRETIZADO: "Concretizado",
  CANCELADO: "Cancelado",
  ABANDONADO: "Abandonado",
};

export const ICONS_PER_STATUS_SALE = {
  CONCRETIZADO: "check-circle",
  CANCELADO: "x-circle",
  ABANDONADO: "shopping-bag",
  PENDENTE: "alert-triangle",
  EM_PROCESSAMENTO: "settings",
};

export const SUB_STATUS_SALE = {
  ATIVACAO: "Ativação",
  ACEITE_VOZ: "Aceite de voz",
  ICCID_USO: "ICCID em uso",
  VENCIMENTO_INVALIDO: "Venc. inválido",
  DADO_BANCARIO_INVALIDO: "Dados bancários inválidos",
  PLANO_INVALIDO: "Oferta inválida",
  ACEITE_DECLINADO: "Aceite Declinado",
  ACEITE_NAO_REALIZADO: "Aceite não realizado",
  SUSPEITA_FRAUDE: "Susp. de fraude",
  CREDITO_INSUFICIENTE: "Créd. Insuficiente",
  CANCELADO_SVC: "Canc. SVC",
  DOWNGRADE: "Downgrade",
  AGUARDANDO_PAGAMENTO: "Aguad. pagamento",
  EM_TRATAMENTO: "Em tratamento",
  INDICACAO_RECEBIDA: "Ind. recebida",
  MENOR_IDADE: "Menor idade",
  CLIENTE_NAO_ENCONTRADO: "Cliente não encontrado",
  PAGAMENTO_NAO_AUTORIZADO: "Pagamento não autorizado",
  PAGAMENTO_REALIZADO: "Pagamento realizado",
  PAGAMENTO_PENDENTE: "Pagamento Pendente",
  // AGUARDANDO_PAGAMENTO: "Pagamento pendente",
  PAGAMENTO_ANALISE: "Pagamento em análise",
  PENDENTE: "Pendente",
  ENVIADO: "Enviado",
  ENTREGUE: "Entregue",
  PEDIDO_ENVIADO: "Pedido enviado",
  PEDIDO_REALIZADO: "Pedido realizado",
  AGUARDANDO_BKO: "Aguardando BKO",
  RETORNO_AGENDADO: "Retorno Agendado",
  EMITIDA: "Emitida",
  SCORE_NEGADO: "Score negado",
  SEM_COBERTURA: "Sem cobertura",
  SEM_CONTATO: "Sem contato",
  RECUSA_BOM_PAGADOR: "Recusa bom pagador",
  DESCONHECIDO_NO_LOCAL: "Desconhecido no local",
  ENDERECO_INCORRETO: "Endereço incorreto",
  EMITIDA_EM_OUTRO_CANAL: "Emitida em outro canal",
  PRODUTO_ALVO: "Produto alvo",
  DUPLICADA: "Duplicada",
  DADOS_DIVERGENTES: "Dados divergentes",
  DESISTENCIA_DO_CLIENTE: "Desistência do cliente",
  CRUZAMENTO_DO_CANAL: "Cruzamento do canal",
  DADOS_INCORRETOS: "Dados incorretos",
  CREDITO_NEGADO: "Crédito negado",
  CREDITO_NEGADO_TELECOM: "Crédito negado",
  ERRO_CHECAGEM_CREDITO: "Erro de checagem do crédito",
  TITULAR_FALECIDO: "Titular falecido",
  INVIABILIDADE_TECNICA: "Inviabilidade técnica",
  ENDERECO_INADIMPLENTE: "Endereço inadimplente",
  TESTE: "Teste",
  JA_E_CLIENTE_NO_MESMO_ENDERECO: "Cliente no mesmo endereço",
  CREDITO_NEGADO_EM_ALGUMA_OPERADORA: "Crédito negado na operadora",
  NAO_SOUBE_CONFIRMAR_OS_DADOS: "Não soube confirmar os dados",
  AGUARDANDO_FORMALIZACAO: "Aguardando formalização",
  FORMALIZACAO_FINALIZADA: "Formalização finalizada",
  EM_ANALISE: "Em análise",
  PAGA: "Paga",
  ESTORNADA: "Estornada",
  CANCELADA: "Cancelada",
  AGUARDANDO_BIOMETRIA: "Aguardando biometria",
  AGUARDANDO_RELATORIO_CONSUMIDOR: "Aguardando relatório de consumidor",
  AGUARDANDO_ACEITE_CONSUMIDOR: "Aguardando aceite de consumidor",
  AGUARDANDO_ASSINATURA : "Aguardando assinatura",
  PENDENTE_BIOMETRIA: "Pendente biometria",
  AGUARDANDO: "Aguardando",
  EM_ANDAMENTO: "Em andamento",
  PENDENTE_ATIVACAO: "Pendente ativação",
  PENDENTE_APROVACAO: "Pendente aprovação",
  PENDENTE_CONFIRMACAO: "Pendente confirmação",
  PENDENTE_BKO_SAV: "Pendente BKO SAV",
  RETORNO_BKO_SAV: "Retorno BKO SAV",
  FINALIZADA: "Finalizada",
  INSTALACAO_AGENDADA: "Instalação Agendada",
  INSTALACAO_NAO_AGENDADA: "Instalação Não Agendada",
  INSTALADO: "Instalado",
  NAO_INSTALADO: "Não instalado",
  AGUARDANDO_AGENDAMENTO: "Aguardando agendamento",
  // AGUARDANDO_FORMALIZACAO: "Aguardando formalização",
  AGUARDANDO_ATENDIMENTO: "Aguardando atendimento",
  PENDENTE_BKO: "Pendente BKO",
  PENDENCIA_SISTEMICA: "Pendencia sistemica",
  PENDENTE_VENDEDOR: "Pendente vendedor",
  AGUARDANDO_VALIDACAO_CREDITO_MANUAL: "Aguardando validação de crédito manual",
  AGUARDANDO_SOLICITACAO_APARELHO: "Aguardando solicitação de aparelho",
  AGUARDANDO_OPERADOR_LOGISTICO: "Aguardando operador logistico",
  AGUARDANDO_TRANSPORTE: "Aguardando Transporte",
  EM_TRANSITO: "Em Trânsito",
  APARELHO_DISPONIVEL: "Aparelho Disponível",
  AGUARDANDO_DEVOLUCAO_APARELHO: "Aguardando Devolução do Aparelho",
  APARELHO_DEVOLVIDO: "Aparelho Devolvido",
};

export const MOBILE_SALES_FLOW_STEP_LABELS = {
  OPENING: "Abertura",
  REGISTER: "Cadastro",
  OFFER: "Oferta",
  PAYMENT: "Pagamento",
  CONFIRMATION: "Confirmação",
  ACCEPTANCE: "Aceite",
};

export const MOBILE_SALES_FLOW_ROUTE_LABELS = {
  OPENING_SALE: "opening-sale",
  COMPLETE_REGISTRATION: "complete-registration",
  AVAILABLE_OFFERS: "available-offers",
  CUSTOMIZE_INVOICE: "customize-invoice",
  ORDER_CONFIRMATION: "order-confirmation",
  ORDER_ACCEPTANCE: "order-acceptance",
};

export const STATUS_MISSIONS_NEAR_SCHEDULED_ACTIVE_DELAYED = [
  "PROXIMA",
  "AGENDADA",
  "ATRASADA",
  "ATIVA",
];

export const STATUS_MISSIONS_NEAR_SCHEDULED_DELAYED = [
  "PROXIMA",
  "AGENDADA",
  "ATRASADA",
];

export const STATUS_MISSIONS_CANCELLED_FINISHED = ["CONCLUIDA", "CANCELADA"];

export const MISSION_STATUS_BADGE = {
  DISPONIVEL: "info-teal",
  AGENDADA: "success",
  CONCLUIDA: "disabled",
  CANCELADA: "error",
  PROXIMA: "warn",
  ATRASADA: "warn",
  ATIVA: "active",
  SEM_ACEITE: "warn",
  NAO_EXECUTADA: "error",
};

export const MISSION_TEXT_BADGE = {
  DISPONIVEL: "disponível",
  AGENDADA: "agendada",
  CONCLUIDA: "concluída",
  CANCELADA: "cancelada",
  ATIVA: "Ativa",
  PROXIMA: "Próxima",
  ATRASADA: "Atrasada",
  SEM_ACEITE: "Sem aceite",
  NAO_EXECUTADA: "Não executada",
};

export const COMMISSION_TYPES_TEXT = {
  VENDA: "Comissão",
  TRANSFERENCIA: "Transferência",
  DIARIA: "Diária",
  COMISSAO: "Comissão",
  BONUS: "Bônus",
  ACESSO: "Acesso",
  COMPARTILHAMENTO: "Compartilhamento",
};

export const DEFAULT_TOTAL_ITEMS_PER_PAGE = process.env
  .VUE_APP_TOTAL_ITEMS_PER_PAGE || [
  { text: "20", value: "20" },
  { text: "50", value: "50" },
  { text: "100", value: "100" },
  { text: "150", value: "150" },
];

export const { OPENING, REGISTER, OFFER, PAYMENT, CONFIRMATION, ACCEPTANCE } =
  MOBILE_SALES_FLOW_STEP_LABELS;

export const {
  OPENING_SALE,
  COMPLETE_REGISTRATION,
  AVAILABLE_OFFERS,
  CUSTOMIZE_INVOICE,
  ORDER_CONFIRMATION,
  ORDER_ACCEPTANCE,
} = MOBILE_SALES_FLOW_ROUTE_LABELS;

export const STEP_ROUTES = {
  [OPENING_SALE]: 1,
  [COMPLETE_REGISTRATION]: 2,
  [AVAILABLE_OFFERS]: 3,
  [CUSTOMIZE_INVOICE]: 4,
  [ORDER_CONFIRMATION]: 5,
  [ORDER_ACCEPTANCE]: 6,
};

export const ROUTES_PER_STEP = {
  [OPENING_SALE]: OPENING,
  [COMPLETE_REGISTRATION]: REGISTER,
  [AVAILABLE_OFFERS]: OFFER,
  [CUSTOMIZE_INVOICE]: PAYMENT,
  [ORDER_CONFIRMATION]: CONFIRMATION,
  [ORDER_ACCEPTANCE]: ACCEPTANCE,
};

export const IDWALL_LINKS = {
  DEVELOP_VENDAPP: "http://localhost:8080/idwall",
  DEVELOP_VEMQDA: "http://localhost:8080/idwall",
  HMG_VENDAPP: "https://hmg.vendapp.com.br/idwall",
  HMG_VEMQDA: "https://hmg.vemqda.com.br/idwall",
  PRODUCTION_VENDAPP: "https://vendapp.com.br/idwall",
  PRODUCTION_VEMQDA: "https://vemqda.com.br/idwall",
};

export const IDWALL_LINKS_VIVO = {
  DEVELOP_VENDAPP: "http://localhost:8080/vivo",
  HMG_VENDAPP: "https://hmg.vendapp.com.br/vivo",
  PRODUCTION_VENDAPP: "https://vendapp.com.br/vivo",
};

export const IDWALL_AVAILABLE_RETAIL_CHAINS = [
  "aguia_brasil",
  "b_j_telecom",
  "americo_textil",
  "amais_telecom",
  "first_telecom",
  "lani_solucoes_e_internet",
  "lm_telecom_ltda",
  "resulta_isp",
  "consultoria_ip_marketing_telecomunicacoes",
  "ultra_conecta",
  "terra_lojas",
  "eyes_sistemas",
  "atitude_seven",
  "e_r_digital",
];

export const COMMERCIAL_MAP_TITLES = [
  {
    code: "produtos_e_precos",
    text: "Produtos e Preços",
  },
  {
    code: "extrato_de_vendas",
    text: "Extrato de Vendas",
  },
  {
    code: "comissionamento",
    text: "Comissionamento",
  },
  {
    code: "estornos",
    text: "Estornos",
  },
  {
    code: "triangulacao",
    text: "Triangulação",
  },
];

export const TECHNICAL_MAP_TITLES = [
  {
    code: "reset_de_senha",
    text: "Reset de senha",
  },
  {
    code: "problemas_de_acesso",
    text: "Problemas acesso",
  },
  {
    code: "criacao_de_usuario",
    text: "Criação de usuário",
  },
  {
    code: "criacao_de_rede",
    text: "Criação de rede",
  },
  {
    code: "criacao_de_pdv",
    text: "Criação de pdv",
  },
  {
    code: "alteracao_de_dados_cadastrais",
    text: "Alteração de dados cadastrais",
  },
  {
    code: "liberar_rede_pdv_para_usuario",
    text: "Liberar rede PDV para usuário",
  },
  {
    code: "alterar_ou_inativar_perfil_usuario",
    text: "Alterar ou inativar perfil usuário",
  },
  {
    code: "inativar_rede_pdv",
    text: "Inativar rede PDV",
  },
  {
    code: "dificuldade_emissao_de_venda",
    text: "Dificuldade Emissão de Vendas",
  },
];

export const AVAILABLE_ROLES = {
  GERENTE: "Gerente",
  PROPRIETARIO: "Proprietário",
  COORDENADOR: "Coordenador",
  VENDEDOR: "Vendedor",
  SUPERVISOR: "Supervisor",
  PROMOTOR: "Promotor",
  PROMOTOR_MISSAO: "Promotor da Missão",
  AFILIADO: "Afiliado",
  MASTER: "Master",
  COMM_ADM_VENDAS: "Commcenter Administrador de Vendas",
  BKO_GERENTE: "BKOffice Gerente",
  BKO: "BKOffice",
  GERENTE_LEADS: "Gerente de Leads",
};
